import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
  SiAnsys,
  SiDassaultsystemes,
  SiAltiumdesigner,
} from "react-icons/si";
import coppeliasim from './Icons/coppeliasim.png';
import gazebo from './Icons/gazebo.png';
import rviz from './Icons/rviz.png';
function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiMacos />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAnsys />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDassaultsystemes />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAltiumdesigner />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={coppeliasim} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={gazebo} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={rviz} />
      </Col>
    </Row>
  );
}

export default Toolstack;
