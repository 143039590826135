import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Shashank Seth </span>
           
            <br /> I am a final year Electrical engineering student pursuing dual degree with B.Tech in Electrical Engineering and M.Tech in Power, Electronics and Drives
           from <span className="purple">IIT Bhubaneswar.</span>
            <br />
          <br/>
            Position of Responsibility holding/held
          </p>
          <ul>
          <li className="about-activity">
              <ImPointRight /> Students Internshp Co-ordinator, 2022
            </li>
            <li className="about-activity">
              <ImPointRight /> Secretary: Robotics Society IIT Bhubaneswar
            </li>
            <li className="about-activity">
              <ImPointRight /> Students Guide for batch of 2021
            </li>
            <li className="about-activity">
              <ImPointRight /> Alma Fiesta : Publicity Team.
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Believe in yourself, either you achieve or you learn!"{" "}
          </p>
          <footer className="blockquote-footer">Shashank</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
